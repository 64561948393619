import { useIntl } from 'gatsby-plugin-intl';

export const useGetTestimonials = () => {
  const intl = useIntl();
  return parseTestimonials(intl);
};

const parseTestimonials = dataIntl => {
  const testimonialsParsed = [
    {
      author: dataIntl.formatMessage({ id: 'testimonials.content.0.author' }),
      quote: dataIntl.formatMessage({ id: 'testimonials.content.0.quote' }),
    },
    {
      author: dataIntl.formatMessage({ id: 'testimonials.content.1.author' }),
      quote: dataIntl.formatMessage({ id: 'testimonials.content.1.quote' }),
    },
    {
      author: dataIntl.formatMessage({ id: 'testimonials.content.2.author' }),
      quote: dataIntl.formatMessage({ id: 'testimonials.content.2.quote' }),
    },
    {
      author: dataIntl.formatMessage({ id: 'testimonials.content.3.author' }),
      quote: dataIntl.formatMessage({ id: 'testimonials.content.3.quote' }),
    },
    {
      author: dataIntl.formatMessage({ id: 'testimonials.content.4.author' }),
      quote: dataIntl.formatMessage({ id: 'testimonials.content.4.quote' }),
    },
    {
      author: dataIntl.formatMessage({ id: 'testimonials.content.5.author' }),
      quote: dataIntl.formatMessage({ id: 'testimonials.content.5.quote' }),
    },
  ];
  return testimonialsParsed;
};
