import React from 'react';
import HeaderSection from '../../components/Testimonials/HeaderSection';
import TestimonialsContainer from '../../components/Testimonials/TestimonialsContainer';

const TestimonialsSection = () => {
  return (
    <section className="flex flex-col justify-center items-center bg-primary-black py-8">
      <HeaderSection />
      <TestimonialsContainer />
    </section>
  );
};
export default TestimonialsSection;
