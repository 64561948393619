import React from 'react';
import HeroContainer from '../../components/Hero/HeroContainer';
import HeroImage from '../../components/Hero/HeroImage';
import HeroLeadingText from '../../components/Hero/HeroLeadingText';
import Image from '../../components/Image';

const HeroSection = () => {
  return (
    <div className="grid">
      <Image
        style={{ gridArea: '1 / 1' }}
        fileName="dima-painter-hero.jpg"
        alt="Dima Painter Hero Image"
        title="Dima Painter Hero Image"
      />
      <div
        style={{ gridArea: '1 / 1', zIndex: 2 }}
        className="justify-center items-center flex flex-col text-center text-white mx-auto p-5 lg:p-0 lg:mt-0"
      >
        <HeroLeadingText />
      </div>
    </div>
  );
};

export default HeroSection;
