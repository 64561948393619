import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import FaqList from './FaqList';

const FaqContent = () => {
  const intl = useIntl();
  const faqs = [
    {
      question: intl.formatMessage({ id: 'home.FAQ.questions.0.question' }),
      answer: intl.formatMessage({ id: 'home.FAQ.questions.0.answer' }),
    },
    {
      question: intl.formatMessage({ id: 'home.FAQ.questions.1.question' }),
      answer: intl.formatMessage({ id: 'home.FAQ.questions.1.answer' }),
    },
    {
      question: intl.formatMessage({ id: 'home.FAQ.questions.2.question' }),
      answer: intl.formatMessage({ id: 'home.FAQ.questions.2.answer' }),
    },
  ];
  return (
    <div className="lg:ml-12">
      <Heading text={intl.formatMessage({ id: 'home.FAQ.title' })} />
      <Description text={intl.formatMessage({ id: 'home.FAQ.description' })} />
      <dl className="mt-12 text-primary-navy">
        <FaqList faqs={faqs} />
      </dl>
    </div>
  );
};

const Heading = ({ text }) => {
  return (
    <h2 className="text-4xl sm:text-5xl font-black tracking-wide text-center text-primary-navy lg:text-left">
      {text}
    </h2>
  );
};

const Description = ({ text }) => {
  return (
    <p className="max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-gray-500">
      {text}
    </p>
  );
};

export default FaqContent;
