import React from 'react';

const FaqContainer = ({ children }) => {
  return (
    <div className="relative">
      <div className="max-w-screen-xl mx-auto py-16 lg:py-20">
        <div className="flex justify-center p-8">{children}</div>
      </div>
    </div>
  );
};

export default FaqContainer;
