//#region Components Imported
import React, { useState } from 'react';
import Slider from 'react-slick';
import Testimonial from './Testimonial';
import Quote from './Quote';
import Author from './Author';
import ResumeTestimonial from './ResumeTestimonial';
import { useGetTestimonials } from '../../hooks/useGetTestimonials';
//#endregion Components Imported

//#region SVG
import QuotesIcon from '../../images/icons/quotesIcon.svg';
import ArrowsGroup from '../../components/Testimonials/ArrowsGroup';
import ArrowLeftIcon from '../../images/icons/arrowLeftIcon.svg';
import ArrowRightIcon from '../../images/icons/arrowRightIcon.svg';
//#endregion

//#region CSS Imported
import 'slick-carousel/slick/slick.css';
//#endregion CSS Imported

const TestimonialsContainer = () => {
  const [testimonialShowing, setTestimonialShowing] = useState(null);
  const testimonials = useGetTestimonials();
  return (
    <>
      <Slider
        arrows={false}
        ref={setTestimonialShowing}
        className="w-full md:w-4/5 lg:w-1/2"
      >
        {testimonials.map((testimonialDetail, index) => (
          <Testimonial key={index} testimonial={testimonialDetail}>
            <QuotesIcon className="w-8 absolute top-6 left-6 md:top-9 md:left-8 lg:top-11 xl:top-14 fill-current opacity-50 text-primary-navy" />
            <Quote quote={testimonialDetail.quote} />
            <ResumeTestimonial>
              <Author
                author={testimonialDetail.author}
              />
              <ArrowsGroup>
                <ArrowLeftIcon
                  className="w-12 md:w-8 mr-1.5 fill-current cursor-pointer text-primary-navy hover:text-primary-black"
                  onClick={testimonialShowing?.slickPrev}
                />
                <ArrowRightIcon
                  className="w-12 md:w-8 ml-1.5 fill-current cursor-pointer text-primary-navy hover:text-primary-black"
                  onClick={testimonialShowing?.slickNext}
                />
              </ArrowsGroup>
            </ResumeTestimonial>
          </Testimonial>
        ))}
      </Slider>
    </>
  );
};

export default TestimonialsContainer;
