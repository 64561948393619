import React, { useState } from 'react';
import { motion } from 'framer-motion';
import PlusIcon from '../../images/icons/plus.svg';
import MinusIcon from '../../images/icons/minus.svg';

const FaqList = ({ faqs = [] }) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return faqs.map((faq, index) => (
    <div
      className="cursor-pointer group mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none"
      key={index}
      onClick={() => {
        toggleQuestion(index);
      }}
    >
      <FaqQuestion
        index={index}
        activeQuestionIndex={activeQuestionIndex}
        question={faq.question}
      />
      <FaqAnswer
        index={index}
        activeQuestionIndex={activeQuestionIndex}
        answer={faq.answer}
      />
    </div>
  ));
};

const FaqQuestion = ({ activeQuestionIndex, index, question }) => {
  return (
    <dt className="flex justify-between items-center">
      <span className="text-lg lg:text-xl font-semibold">{question}</span>
      <span className="ml-2 bg-primary-navy text-gray-100 p-1 rounded-full group-hover:bg-primary-navy group-hover:text-gray-200 transition duration-300">
        {activeQuestionIndex === index ? (
          <MinusIcon className="w-4 h-4" />
        ) : (
          <PlusIcon className="w-4 h-4" />
        )}
      </span>
    </dt>
  );
};

const FaqAnswer = ({ activeQuestionIndex, index, answer }) => {
  return (
    <motion.dd
      variants={{
        open: { opacity: 1, height: 'auto', marginTop: '16px' },
        collapsed: { opacity: 0, height: 0, marginTop: '0px' },
      }}
      initial="collapsed"
      animate={activeQuestionIndex === index ? 'open' : 'collapsed'}
      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
      className="pointer-events-none text-sm sm:text-base leading-relaxed"
    >
      {answer}
    </motion.dd>
  );
};

export default FaqList;
