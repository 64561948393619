import React from 'react';
import FaqContainer from '../../components/FAQ/FaqContainer';
import FaqContent from '../../components/FAQ/FaqContent';
import FaqImage from '../../components/FAQ/FaqImage';

const FaqSection = () => {
  return (
    <>
      <FaqContainer>
        <FaqImage />
        <FaqContent />
      </FaqContainer>
    </>
  );
};

export default FaqSection;
