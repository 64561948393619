import React from 'react';

const Quote = ({ quote }) => {
  return (
    <blockquote className="tracking-wide text-lg text-center lg:text-left font-light">
      {quote}
    </blockquote>
  );
};

export default Quote;
