import React from 'react';
import { Link, useIntl } from 'gatsby-plugin-intl';

const HeroLeadingText = () => {
  const intl = useIntl();
  return (
    <>
      <h1 className="font-black text-4xl md:text-6xl leading-relaxed max-w-3xl">
        {intl.formatMessage({ id: 'home.leadingText' })}{' '}
        {intl.formatMessage({ id: 'home.leadingTextStylized' })}
      </h1>
      <p className="my-5 lg:my-8 text-base lg:text-xl font-medium text-gray-100 max-w-lg mx-auto lg:mx-0">
        {intl.formatMessage({ id: 'home.subheading' })}
      </p>
      <Link to="/contact">
        <button className="font-bold w-full px-8 lg:px-10 py-3 rounded bg-primary-navy text-primary-white hover:opacity-95 hover:shadow-outline focus:outline-none transition duration-300">
          {intl.formatMessage({ id: 'home.ctaBtnText' })}
        </button>
      </Link>
    </>
  );
};

const HighLightedText = ({ text }) => {
  return (
    <p className="bg-primary-navy text-primary-white px-4 transform -skew-x-12 inline-block">
      {text}
    </p>
  );
};

export default HeroLeadingText;
