import React from 'react';

function Author({ author }) {
  return (
    <div className="flex flex-col items-center md:items-start">
      <div id="authorName" className="font-bold text-primary-navy capitalize">
        {author}
      </div>
    </div>
  );
}

export default Author;
