import React from 'react';
import Image from '../Image';

const FaqImage = () => {
  return (
    <div className="hidden lg:block w-5/12 flex-shrink-0">
      <Image
        className="rounded-xl"
        alt="Dima Painter FAQ"
        fileName="dima-painter-faq.jpeg"
        title="FAQ"
      />
    </div>
  );
};

export default FaqImage;
