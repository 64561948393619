import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import SEO from '../components/seo';
import FaqSection from '../sections/home/FaqSection';
import TestimonialsSection from '../sections/home/TestimonialsSection';
import HeroSection from '../sections/home/HeroSection';

const IndexPage = () => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: 'seo.home.title' })}
        description={intl.formatMessage({ id: 'seo.home.description' })}
      />
      <HeroSection />
      <TestimonialsSection />
      <FaqSection />
    </Layout>
  );
};
export default IndexPage;
