import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

const HeaderSection = () => {
  const intl = useIntl();
  return (
    <div>
      <h3 className="text-primary-white text-4xl md:text-6xl text-center font-bold tracking-wide">
        {intl.formatMessage({ id: 'testimonials.sectionTitle' })}
      </h3>
      <h6 className="text-primary-white text-base font-light text-center tracking-wide mt-2 w-4/5 md:w-1/2 mx m-auto">
        {intl.formatMessage({id: 'testimonials.description'})}
      </h6>
    </div>
  );
};

export default HeaderSection;
