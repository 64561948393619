import React from 'react';

const ResumeTestimonial = ({ children }) => {
  return (
    <div className="flex flex-col md:flex-row justify-center md:justify-between mt-5">
      {children}
    </div>
  );
};

export default ResumeTestimonial;
