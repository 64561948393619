import React from 'react';

const Testimonial = ({ children }) => {
  return (
    <div className="flex flex-col justify-between relative mx-5 my-4 py-12 md:py-14 lg:py-16 px-4 md:px-16 bg-primary-white text-primary-black rounded-2xl focus:outline-none">
      {children}
    </div>
  );
};

export default Testimonial;
